export const CountryIdAlphaCode3 = {
  PERU: 'PER',
  BRAZIL: 'BRA',
  COLOMBIA: 'COL',
  MALAYSIA: 'MYS',
  CHILE: 'CHL',
  ARGENTINA: 'ARG',
  MEXICO: 'MEX'
}

export const CountryIdAlphaCode2 = {
  PERU: 'PE',
  BRAZIL: 'BR',
  COLOMBIA: 'CO',
  MALAYSIA: 'MY',
  CHILE: 'CL',
  ARGENTINA: 'AR',
  MEXICO: 'MX'
}
