import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'

const adapter = validatorAdapter('Yup', yup)

export const SUBSTATUS = {
  ACTIVE_CN_PENDING_CSC: 41,
  ACTIVE_DEBT: 23,
  ACTIVE_SELLER: 7,
  ADDRESS_INCOMPLETE: 66,
  ADDRESS_VALIDATION_FAIL: 15,
  AGREEMENTS_SERVICE_ERROR: 75,
  ALLOCATION_STRUCTURE_ERROR: 51,
  APPROVED_BY_GR: 45,
  APPROVED_BY_GV: 46,
  APPROVED_SELLER: 6,
  ATTACHMENT_MISSING: 70,
  ATTACHMENT_VALIDATION_FAIL: 20,
  ATTACHMENT_VALIDATION_SUCCESS: 71,
  BRM_BAD_PARAMETERS: 52,
  CEP_VALIDATION_FAIL: 16,
  CESSADA: 33,
  CLEARSALE_VALIDATION_FAIL: 40,
  CN_ATIVA: 34,
  CREATING_PERSON: 32,
  CREDIT_RATING_ERROR: 65,
  CREDIT_VALIDATION: 5,
  DOCUMENT_POSTED: 39,
  EMAIL_EXISTENT: 1,
  ERROR_COMPLETE_DATA_ON_BRM: 62,
  ESTABLISHMENT_NOT_FOUND: 54,
  EXISTENT_EMAIL_ON_GERA: 60,
  EXISTENT_ROLE: 48,
  FAILURE_CEASE_GERA: 56,
  FINAL_DATA_VALIDATION: 67,
  GERA_DEBT_SERVICE_ERROR: 63,
  INTEGRATION_ERROR_WITH_GERA: 50,
  INVALID_ADDRESSES: 76,
  INVALID_DELIVERY_ADDRESS: 79,
  INVALID_MAILING_ADDRESS: 78,
  MANUALLY_APPROVED: 61,
  NAME_VALIDATION: 4,
  OPEN_TITLE: 37,
  PENDING_ADDRESS_BLACKLIST_REVIEW: 57,
  PENDING_ADDRESS_REVIEW: 26,
  PENDING_ALLOCATE: 12,
  PENDING_APPROVAL: 59,
  MISSING_CONFIRMATION_CODE: 74,
  PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED: 2,
  PENDING_CREDIT_ANALISYS: 64,
  PENDING_CSC_APPROVAL: 13,
  PENDING_DATA: 8,
  PENDING_DIGITAL_SPACE: 73,
  PENDING_DOCUMENTATION: 9,
  PENDING_EMAIL_VERIFICATION: 30,
  PENDING_GR_APPROVAL: 18,
  PENDING_GV_APPROVAL: 19,
  PENDING_MANUAL_ASSIGNMENT: 49,
  PENDING_PREPAID: 22,
  PENDING_REGISTRATION_APPROVAL: 21,
  PENDING_RESTART_REVIEW: 27,
  PROCESSING: 14,
  QUARENTINE: 3,
  REJECTED_BY_BLACKLIST: 55,
  REJECTED_BY_CSC: 28,
  REJECTED_BY_GR: 31,
  REJECTED_BY_GV: 24,
  REJECTED_BY_MINIMAL_PERIOD_AS_CEASED: 69,
  REJECTED_BY_REGISTER_TEAM: 29,
  REJECTION_REASON: 58,
  RENUNCIATION_CANDIDATE: 11,
  RENUNCIATION_NATURA: 10,
  RESTRICTED_ADDRESS: 25,
  RESTRICTED_ROLE: 38,
  RESTRICTION_EMAIL: 36,
  RESTRICTION_TELEPHONE: 42,
  RETRIEVE_CYCLE_ERROR: 53,
  SITIDATA_SERVICE_ERROR: 77,
  SYSTEM_FAIL: 47,
  TERM_OF_ACCEPTANCE: 35,
  WITHOUT_ADDRESS: 68,
  WORKFLOW_EXECUTION_ERROR: 72,
}

export default class Substatus extends Entity {
  static SCHEMA = {
    description: adapter(yup.string().nullable()),
    id: adapter(yup.number().nullable())
  }

  get pendingGrApproval() {
    return this.id === SUBSTATUS.PENDING_GR_APPROVAL
  }

  get pendingGvApproval() {
    return this.id === SUBSTATUS.PENDING_GV_APPROVAL
  }

  get pendingEmailVerification() {
    return this.id === SUBSTATUS.PENDING_EMAIL_VERIFICATION
  }
}

export const substatusEnum = Object.entries(SUBSTATUS).reduce(substatusReduce, {})

function substatusReduce(substatus, [name, id]) {
  return { ...substatus, [id] : name.toLowerCase() }
}
