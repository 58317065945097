import { getSectionTitle } from './strategies/getSectionTitle'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmitting } from './Submit'

export function buildPersonalDataProps(deps) {
  const { title, subtitle, label } = getSectionTitle()
  const { person, loader: { personLoading, resendLoading, globalLoading }, 
    locale: { id, tenantId },
    user: { authenticated, authentication }, 
  } = deps
  const {
    personId,
    businessModel: { businessModelId, functionId, roleId },
    addresses, emails, name, phones
  } = person
  const onSubmit = onSubmitting(deps)

  let identityInfo = {
    personId,
    tenantId,
    countryId: id,
    companyId: 1,
    sourceSystem: 7,
    role: roleId,
    businessModel: businessModelId,
    functionId,
  }
  if (authenticated) {
    identityInfo = Object.assign(identityInfo,  { authorization: authentication.accessToken } )
  }
  
  return {
    touchAfterReload,
    formSchema: buildFormSchema(deps),
    disabledSubmit: true,
    onSubmit,
    step: {
      title,
      subtitle,
      label
    },
    loader: personLoading || resendLoading || globalLoading,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'personalData',
      identityInfo,
      person: { addresses , emails, name, phones,
        introducerSellerInformation: {
          introducerSellerId:
          person.additionalInformation.introducerSellerId,
        }
      },
      onSubmit
    }
  }
}
