import { getMalaysiaParsedData } from './getParsedData.mys'
import { getDefaultParsedData } from './getParsedData.default'
import { getChileParsedData } from './getParsedData.chl'
import { getColombiaParsedData } from './getParsedData.col'
import { getMexicoParsedData } from './getParsedData.mex'
import { getArgentinaParsedData } from './getParsedData.arg'

const strategies = {
  MYS: getMalaysiaParsedData,
  CHL: getChileParsedData,
  COL: getColombiaParsedData,
  ARG: getArgentinaParsedData,
  MEX: getMexicoParsedData,
  __default__: getDefaultParsedData
}

export const getParsedData = (localeId, { data, configs, person }) => {
  const strategy = strategies[localeId]
    ? strategies[localeId]
    : strategies.__default__
  return strategy({ data, configs, person })
}
