import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import { IntlProvider } from 'react-intl'

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/include-aliases'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/dist/locale-data/es'
import '@formatjs/intl-relativetimeformat/dist/locale-data/pt'

import messages_pt_BR from 'src/assets/locales/pt_BR.json'
import messages_en_US from 'src/assets/locales/en_US.json'
import messages_es_CO from 'src/assets/locales/es_CO.json'
import messages_es_PE from 'src/assets/locales/es_PE.json'
import messages_en_MY from 'src/assets/locales/en_MY.json'
import messages_es_CL from 'src/assets/locales/es_CL.json'
import messages_es_AR from 'src/assets/locales/es_AR.json'
import messages_es_MX from 'src/assets/locales/es_MX.json'

const messages = {
  en_US: messages_en_US,
  pt_BR: messages_pt_BR,
  es_CO: messages_es_CO,
  es_PE: messages_es_PE,
  en_MY: messages_en_MY,
  es_CL: messages_es_CL,
  es_AR: messages_es_AR,
  es_MX: messages_es_MX,
}

export class Locale extends Component {
  render() {
    const { children, locale: { language, locale, fallbackLocale }, messages } = this.props

    moment.locale(language)

    return (
      <IntlProvider
        locale={language}
        key={language}
        messages={messages[locale] || messages[fallbackLocale]}>
        {children}
      </IntlProvider>
    )
  }
}

Locale.propTypes = {
  children: PropTypes.node,
  locale: PropTypes.object,
  messages: PropTypes.object
}

Locale.defaultProps = {
  locale: {
    language: 'pt_BR'
  },
  messages
}

export function mapStateToProps({ locale: { locale } }, ownProps) {
  return {
    ...ownProps,
    locale
  }
}

export default connect(mapStateToProps)(Locale)
