import * as yup from 'yup'
import TagManager from 'react-gtm-module'
import { defineMessages } from 'react-intl'
import { isDefaultFlowToggleActive } from '../../../config'
import { getFullNameInputInfoText } from './strategies/getFullNameInputInfoText'

export const messages = defineMessages({
  name: {
    defaultMessage: 'Full Name',
    id: 'form.field.name'
  },
  name_placeholder: {
    defaultMessage: 'Fill your full name',
    id: 'form.field.name.placeholder'
  },
  fullname_error: {
    defaultMessage: 'Must have at least 2 words',
    id: 'form.field.fullname.error'
  },
  fullname_info_text: {
    defaultMessage: 'Should be the same as in your document',
    id: 'form.field.fullname.infoText'
  }
})

const dependencies = {
  messages
}

const allowedCharsRegex = new RegExp(/a-zãàáâèéêìíîõòóôùúüûçñ'` /)
const allowedCharsListRegex = new RegExp(`^[${allowedCharsRegex.source}]+$`, 'i')

export default function Builder(intl, injection) {
  const messages = { ...dependencies.messages, ...injection.messages }
  const { person: { name: defaultValue }, customProps, locale: { configs: { localization: { personalData } }}} = injection

  const fullnameError = intl.formatMessage(messages['fullname_error'])

  const pushDataLayer = (values) => {
    const { value } = values
    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationPersonalData',
        fieldName: intl.formatMessage(messages['name']),
        value,
      }
    })
  }
  const fullNameInputProps = { ...customProps, infoText: getFullNameInputInfoText(personalData) }

  return {
    id: 'name',
    defaultValue,
    label: intl.formatMessage(messages['name']),
    placeholder: isDefaultFlowToggleActive() ? intl.formatMessage(messages['name_placeholder']) : null,
    onKeyDown: lettersOnly,
    onPaste: lettersOnlyPaste,
    onBlur: pushDataLayer,
    customProps: fullNameInputProps,
    validations: yup.string()
      .test('FULLNAME', fullnameError, validateFullname)
  }
}


export function lettersOnly(event) {
  const keyCode = event.keyCode ? event.keyCode : event.which
  const value = String.fromCharCode(keyCode)
  const allowedKeys = [8, 9, 32, 37, 39, 46]

  if (allowedKeys.includes(keyCode)) return

  return checkLettersOnly(event, value)
}

export function lettersOnlyPaste(event) {
  const { clipboardData } = event || window
  const value = clipboardData && clipboardData.getData('Text')

  return checkLettersOnly(event, value)
}

export function checkLettersOnly(event, value) {
  const allowed = allowedCharsListRegex.test(value.trim())

  return !allowed && event.preventDefault()
}

export function validateFullname(value) {
  if (!value) return false

  value = value.replace(/\s{2,}/g, " ")
  const result = value.split(" ")

  if (result.length > 1) {
    return result[1].length > 0
  } else {
    return false
  }
}

export function checkSpecialCharacters(message) {
  return yup
    .string()
    .trim()
    .matches(allowedCharsListRegex, { message, excludeEmptyString: true })
    .required()
}
