const REGISTER_ORIGIN_GLOBAL = 'REGISTER_ORIGIN_GLOBAL'
const REGISTER_ORIGIN_MINEGOCIO = 'REGISTER_ORIGIN_MINEGOCIO'
const REGISTER_ORIGIN_UNDEFINED = 'REGISTER_ORIGIN_UNDEFINED'
const REGISTER_ORIGIN_AVON_MIGRATION = 'REGISTER_ORIGIN_AVON_MIGRATION'

const sourceSystemList = {
  4: REGISTER_ORIGIN_GLOBAL,
  5: REGISTER_ORIGIN_GLOBAL,
  6: REGISTER_ORIGIN_GLOBAL,
  7: REGISTER_ORIGIN_GLOBAL,
  12: REGISTER_ORIGIN_AVON_MIGRATION,
  50: REGISTER_ORIGIN_MINEGOCIO
}

export const getOrigen = (sourceSystemId) => {
  return sourceSystemList[sourceSystemId] || REGISTER_ORIGIN_UNDEFINED
}