import { isEnableThemeElo } from "../../config"
import { COMPANY_ID, COMPANY_NAME } from "../../models/Company"
import { CountryIdAlphaCode2 } from "../../models/country"
import { getCountryByUrl } from "../getCountryByUrl"


export const enableThemeElo = () => {
  const availableCountries = process.env.ENABLE_THEME_ELO_TO_COUNTRY?.split(',')
  const currentLocale = getStorageValue('currentLocale', {
    companyId: COMPANY_ID.NATURA
  })
  const isActivatedToCountry = availableCountries?.includes(getCountryByUrl())

  const countryLocale = getStorageValue('country', CountryIdAlphaCode2.BRAZIL)
  const isActivatedToCountryStorage = availableCountries?.includes(countryLocale?.toUpperCase())
  const currentCompanyStorage = getStorageValue('company', COMPANY_NAME.NATURA)

  return isEnableThemeElo() 
    && (currentLocale?.companyId === COMPANY_ID.NATURA || currentCompanyStorage?.toUpperCase() === COMPANY_NAME.NATURA) 
    && (isActivatedToCountry || isActivatedToCountryStorage)
}


function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key)
  let initial = null
  if(saved !== null) {
    initial = JSON.parse(JSON.stringify(saved))
  }
  return initial || defaultValue
}