import { getMalaysiaRoles } from './getRoles.mys'
import { getChileRoles } from './getRoles.chl'
import { getPeruRoles } from './getRoles.per'
import { getColombiaRoles } from './getRoles.col'
import { getMexicoRoles } from './getRoles.mex'
import { getContext } from 'src/infra/Api/Api.context'

const strategies = {
  MYS: getMalaysiaRoles,
  CHL: getChileRoles,
  PER: getPeruRoles,
  COL: getColombiaRoles,
  MEX: getMexicoRoles,
}

export const getDashboardRoles = (localeId) => {
  const strategy = strategies[localeId]
  return strategy()
}

export const getUserBusinessModel = () => {
  const { locale: { id } } = getContext()
  return getDashboardRoles(id)
}
