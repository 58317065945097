import { isPeruLastNameLogicHandlerActive } from '../../../../../../../../config'

const strategies = {
  PER: isPeruLastNameLogicHandlerActive,
  __default__: () => false
}

export const shouldControlLastNamesLength = (localeId) => {
  const strategy = strategies[localeId] ? strategies[localeId] : strategies.__default__

  return strategy()
}
